.user-custom-table thead th {
    border-top: none; /* Remove top border from table header cells */
    border-bottom: none;
  }
  
  .user-custom-table thead th:first-child {
    border-left: none; /* Remove left border from the first table header cell */
  }
  
  .user-custom-table thead th:last-child {
    border-right: none; /* Remove right border from the last table header cell */
  }
  
  .user-custom-table thead th:only-child {
    border-left: none; /* Remove left border from the single table header cell */
    border-right: none; /* Remove right border from the single table header cell */
  }
  
  .user-custom-table th, .user-custom-table td {
    border-bottom: none; /* Remove bottom border from all table cells */
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    width:  253px;
    height:  64px;
    padding: 25px 16px 25px 16px;
  }
  
  .user-custom-table th {
    font-weight: 600;    
  }

  .user-table-custom-boundries {
       padding: 24px 32px 24px 32px;
       gap: 24px;
       border-radius: 24px;
  }

.user-custom-table th:last-child,
.user-custom-table td:last-child {
  text-align: right;
}
