 
.scroll-bar-filter {
    scroll-padding-right: 50px;
	overflow-y: scroll;
}
.scroll-bar-filter::-webkit-scrollbar {
    width: 5px;
	background-color: #F1F5F9;
  }
  
  /* Track */
  .scroll-bar-filter::-webkit-scrollbar-track {
    background-color: #F1F5F9;
    border-radius: 10px;
  }
   
  /* Handle */
  .scroll-bar-filter::-webkit-scrollbar-thumb {
    background-color: #94A3B8;
    height: 30px;
  }
  
  /* Handle on hover */
  .scroll-bar-filter::-webkit-scrollbar-thumb:hover {
    background-color: #0a0b0c;
  }

  .reset-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 8px 20px;
    border-radius: 42px;
    gap: 8px;
    background: #E2E8F0;
    font-family: 'General Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgb(0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
  }

  .reset-button:hover {
    background: #b4beca;
  }

  .apply-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 8px 20px;
    border-radius: 42px;
    gap: 8px;
    background: #10B981;
    font-family: 'General Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: center;
    color: white;
    cursor: pointer;
    text-decoration: none;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
  }

  .apply-button:hover {
    background: #139e70;
  }