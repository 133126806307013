/* CustomCalendar.css */

.custom-calendar {
    width: 175.6px;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .header-date {
    font-weight: bold;
    font-size: 16px;
  }
  
  .header-icon {
    cursor: pointer;
  }
  
  .react-datepicker {
    width: 100%;
    border-radius: 1.688rem;
    z-index: 100000;
    border-radius: 25px !important;
    border: 1px solid #E2E8F0 !important;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    height: 34px;
    font-size: 12px;
    border-radius: 48px;
    padding: 1px 14px;
    border: 1px solid #475569;
    background-color: white;
    padding-left: 30px;
    width: 170.6px;
  }
  
  .icon-container {
    position: absolute;
    top: 38%;
    left: 10px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
  }
  
  .button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .react-datepicker__month-container {
    width: 360px;
    height: 529px;
    padding: 16px 20px 16px 20px;
    border-radius: 12px;
    border: 1px;
    float: left;
}

.react-datepicker__header {
    text-align: center;
    background: white !important;
    border-top-left-radius: 0.5rem;
    padding: 8px 0;
    border-bottom: 0px !important;
    position: relative;
    width: 320px;
    padding: 5px 0px !important;
    border: 0px 0px 1px 0px;
    gap: 8px;

}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    width: 320px;
    height: 288px;
    font-weight: 400;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 50% !important;
    background-color: #10B981 !important;
    color: rgb(255, 255, 255) !important;
    padding: 1px;
    font-weight: 100;
}

.react-datepicker__day--in-range{
  border-radius: 50% !important;
  background-color: #10B981 !important;
  color: rgb(255, 255, 255) !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    border-radius: 1.3rem;
}

.weekend-day {
    margin: 8px !important; /* Adjust the margin value to increase the gap */
  }

.react-datepicker__day-names {
    margin-bottom: -8px !important;
    margin: 0.499rem !important; 
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0.499rem !important;
}

.react-datepicker__day--in-selecting-range{
  background-color: #10B981 !important;
}