@import 'bootstrap/dist/css/bootstrap.min.css';
@import './Sidebar.css';
@import './OrderTable.css';
@import './RightSideModal.css';
@import './DashboardStyle.css';
@import './Settings.css';
@import './Pagination.css';
@import './UserTable.css';
@import './TableFilter.css';
@import './CustomCalendar.css';


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.save-button {
  padding: 12px;
  border-radius: 20px;
  background-color: #10b981;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
}

.centered-modal {
  position: fixed;
  top: 0;
  right: -400px; /* Adjust this value to control the right offset */
  width: 400px; /* Adjust this value to control the modal width */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  z-index: 10000000;
}

.centered-modal.open {
  right: 0;
}

.spinner-position {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100vh;
}

.spinner-position-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10%;
 }

 .spinner-position-between-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin: 10%;
 }

 .error-text {
  font-size: 12px;
  color: red;
 }

 .horizontal-tabs .nav-tabs {
  display: flex; /* Make the tabs horizontal */
  margin-bottom: 15px;
}

.horizontal-tabs .nav-item {
  flex: 1; /* Distribute the tabs evenly */
  text-align: center; /* Center-align tab titles */
}

.spinner-position-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
 }

 .status-icon {
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-right: 10px;
  background-color: #F8FAFC;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pid-icon {
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-right: 10px;
  background-color: #ECFDF5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.kitter-icon {
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-right: 10px;
  background-color: #FFFBEB;
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-order-status {
  background: #faf1f1;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3e7474;
}

.ready-for-kitting-status {
  background: #F1F5F9;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #1E293B;
}

.kitting-in-progress-status {
  background: #EFF6FF;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #2563EB;
}

.ready-for-shipping-status {
  background: #FFF5EB;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #D97706;
}

.shipping-in-progress-status {
  background: #FFFBEB;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #B7B700;
}

.order-in-transit-status {
  background: #FFF0FF;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #A100A1;
}

.order-delivered-status {
  background: #ECFDF5;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #059669;
}

.cancelled-status {
  background: #FEF2F2;
  width: 150px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #DC2626;
}


.clear-all-filters {
  width: 120px;
  height: 32px;
  padding: 5px 5px 5px 8px;
  border-radius: 32px;
  border: 1px solid; /* You need to specify a border style, e.g., solid, dashed, etc. */
  gap: 5px;
  background: linear-gradient(0deg, #FEF2F2, #FEF2F2);
  color:  #F87171 !important;

}