.custom-table thead th {
    border-top: none; /* Remove top border from table header cells */
    border-bottom: none;
  }
  
  .custom-table thead th:first-child {
    border-left: none; /* Remove left border from the first table header cell */
  }
  
  .custom-table thead th:last-child {
    border-right: none; /* Remove right border from the last table header cell */
  }
  
  .custom-table thead th:only-child {
    border-left: none; /* Remove left border from the single table header cell */
    border-right: none; /* Remove right border from the single table header cell */
  }

  .custom-table th:not(:first-child),
.custom-table td:not(:first-child) {
    width:  253px;
  }
  
  .custom-table th,
.custom-table td{
    border-bottom: none; /* Remove bottom border from all table cells */
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    height:  64px;
    padding: 25px 16px 25px 16px;
  }
  
  .custom-table th {
    font-weight: 600;    
  }

  .table-custom-boundries {
       padding: 24px 32px 24px 32px;
       gap: 24px;
       border-radius: 24px;
  }

/* .custom-table th:nth-last-child(-n+2),
.custom-table td:last-child {
  text-align: right;
} */

/* .custom-table td:nth-last-child(2){
    text-align: right;
    padding-right: 20px !important;
  } */

  .table-responsive {
    min-height: 850px;
  }

.custom-table-2 th:nth-last-child(-n+2),
.custom-table-2 td:last-child {
  text-align: right;
}

.custom-table-2 td:nth-last-child(2){
    text-align: right;
    min-width: 200px;
  }
