/* CustomPagination.css */
.custom-pagination {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .pagination {
    border: none;
  }
  
  .pagination .page-item {
    margin: 2px;
  }
  
  .pagination .page-item a.page-link {
    border: none;
    background-color: transparent;
    color: #1E293B;
    border-radius: 50%; /* To create a circular shape for the page number */
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .pagination .page-item a.page-link:hover {
    background-color: #1E293B;
    color: #fff;
  }
  
  /* Apply styles to the active page item */
  .pagination .page-item.active a.page-link {
    background-color: #1E293B;
    color: #fff;
    border: none; /* Remove border from active page item */
    width: 30px; /* Reset width to maintain circular shape */
    height: 30px; /* Reset height to maintain circular shape */
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #1E293B;
    border-color: none;
    border-radius: 50%;
}

