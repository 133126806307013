.dashboard-container {
  display: flex;
  transition: margin-left 0.3s ease;
  /* margin-left: 280px; */
}

.main-content {
width: Fixed (1,528px);
top: 112px;
left: 312px;
padding: 24px 32px 24px 32px;
border-radius: 24px;
gap: 24px;
transition: margin-left 0.3s ease;
}


.dashboard-header {
  padding: 20px;
}

.dashboard-area {
  width: 100%;
  transition: margin-left 0.3s ease;
  margin-left: 280px;
}

@media (max-width: 979px) {
  .dashboard-area {
      margin-left: 0px; /* Reset margin when window width < 980px */
  }
}

.top-nav {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 20px;
  background-color: transparent !important;
}

.create-button {
  width: 142px;
  height: 45px;
  padding: 12px 18px 12px 20px;
  border-radius: 42px;
  gap: 8px;
  background: #10B981;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  width: auto;
}