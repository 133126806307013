.right-side-modal {
    position: fixed;
    top: 0;
    right: -460px; /* Change this value to adjust the right offset */
    width: 460px; /* Change this value to adjust the modal width */
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .right-side-modal.open {
    right: 0;
  }
  
  .rightside-modal-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 35px;
    overflow: auto;
  }
  
  .close-btn {
    position: absolute;
    top: 2%;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    border: none;
  }
  

  .input-fields {
    margin-top: 40%;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    border: 1px solid #cccccc;
    padding: 10px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s;
    border-radius: 20px !important;
  }
  
  input:focus {
    border-color: #007bff;
  }

  .select-field {
    border: 1px solid #cccccc;
    padding: 10px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s;
    border-radius: 20px !important;
  }
