.setting-form {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 0px 150px 20px 0px
  }
  
  .setting-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 5px;
    width: 100%;
  }
  
  .setting-button {
    background-color: #10b981;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    height: 45px;
    width: 160px;
    margin-top: 30px;
  }
  
  .setting-button:hover {
    background-color: #0c7a5c;
  }

  .setting-label {
    font-weight: 400;
    margin-right: 5px;
  }

  .setting-group {
    display: flex;
    flex-direction: column;
    width: 40%;
  }
  
  @media (max-width: 967px) {
    .setting-form {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
        width: 100%;
      }
      .setting-input {
        width: 100%;
      }
      .setting-button {
        width: 100%;
      }
      .setting-group{
        width: 100%;
      }
  }

