.collapsed-sidebar {
  width: 0;
  /* display: none; */
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in;
  transform: translateX(-280px);
  opacity: 0;
}

.open-sidebar {
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in;
  transform: translateX(0);
  opacity: 1;
}

.open-sidebar-items {
  /* display: inline; */
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in;
  opacity: 1;
}

.collapsed-sidebar-items {
  /* display: none; */
  opacity: 0;
  pointer-events: none; 
}

@media (max-width: 980px) {
  .open-sidebar {
    z-index: 100000;
    position: absolute;
    border-right: 1px solid #F1F5F9;
  }
}

.nav-menu {
  list-style-type: none;
  padding: 20px;
  margin: 0;
}

.nav-menu li {
  margin-bottom: 10px;
  cursor: pointer;
}

.order-list {
  width: 100%;
}

.nav-item {
  color: #1f2937;
  width: 278px;
  height:  60px;
  padding: 18px 4px 18px 4px;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;


}

.nav-link {
  color: #1f2937;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
}

.nav-link:hover {
  background-color: #dbeafe;
  cursor: pointer;

  &.text-primary {
    color: #225397;
  }
}

.text-primary {
  color: #1f2937;
}

.toggle-button {
  cursor: pointer;
  float: right;
  transition: all 1s ease-in;
}

.sidebar {
  background-color: white;
  width: 100%;
  max-width: 280px;
  padding: 25px 5px;
  height: 120vh;
  position: fixed;
  z-index: 1000;
  overflow-y: hidden;
  border-radius: 6px;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in;
}

.logo {
  margin: 10px 15px 20px;
}

.active-item {
  background-color: #dbeafe;
  transition: background-color 0.3s ease-in;
  color: #225397;
}

.menu-item {
  color: #1f2937;
}

.profile-icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sub-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  z-index: 1;
  width: 100px;
}

.sub-menu-item {
  padding: 8px;
  cursor: pointer;
}

.sub-menu-item:hover {
  background-color: #dbeafe;
}

.navbar-brand {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}